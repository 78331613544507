<template lang="pug">
	v-row.rowUpper
		v-col.py-0(v-if='areaSwitch' cols='12' md='6' lg='4')
			v-row.input-wrap.mx-0
				v-col.pa-0.labal-bgc.text-center(cols='3')
					span.caption {{$t('common.cmsList')}}
				v-col.pa-0(cols='9')
					ValidationProvider(rules='required' v-slot='{ errors }')
						v-select.cars-select(ref='area' v-model='area' :items='areaList' multiple required :error-messages='errors' single-line background-color='rgba(255, 255, 255, 0.1)' dense solo item-color='#fff' :menu-props="menuProps")
							template(v-slot:prepend-item)
								v-list-item(@click='areaToggle' v-if='areaList.length > 1')
									v-list-item-content
										v-list-item-title {{$t('common.selectAll')}}
							template(v-slot:selection='{ item, index }')
								v-spacer(v-if='index === 0')
								span(v-if='selectItemName(area, areaList, index)')
									span {{ item }}
								span.grey--text(v-if='selectMultiple(area, areaList, index)') (+{{ area.length - 1 }})
								span(v-if='showSelectAll(area, areaList, index)') {{$t('common.selectAll')}} ({{area.length}})
		v-col.py-0(v-if='domainSwitch' cols='12' md='6' lg='4')
			v-row.input-wrap.mx-0
				v-col.pa-0.labal-bgc.text-center(cols='3')
					span.caption {{$t('charts.domain')}}
				v-col.pa-0(cols='9')
					ValidationProvider(rules='required' v-slot='{ errors }')
						v-select.cars-select(ref='domain' v-model='value' :items='domain' multiple required :error-messages='errors' single-line background-color='rgba(255, 255, 255, 0.1)' dense solo item-color='#fff' :menu-props="menuProps")
							template(v-slot:prepend-item)
								v-list-item(@click='toggle' v-if='domain.length > 1')
									v-list-item-content
										v-list-item-title {{$t('common.selectAll')}}
							template(v-slot:selection='{ item, index }')
								v-spacer(v-if='index === 0')
								span(v-if='selectItemName(value, domain, index)')
									span {{ item }}
								span.grey--text(v-if='selectMultiple(value, domain, index)') (+{{ value.length - 1 }})
								span(v-if='showSelectAll(value, domain, index)') {{$t('common.selectAll')}} ({{value.length}})
		v-col.py-0(v-if='currencyStatus' cols='12' md='6' lg='4')
			v-row.input-wrap.mx-0
				v-col.pa-0.labal-bgc.text-center(cols='3')
					span.caption {{$t('common.curencyList')}}
				v-col.pa-0(cols='9')
					ValidationProvider(rules='required' v-slot='{ errors }')
						v-select.cars-select(ref='currency' v-model='currency' :items='rateList' required :error-messages='errors' single-line background-color='rgba(255, 255, 255, 0.1)' dense solo item-color='#fff' :menu-props="menuProps")
							template(v-slot:selection='{ item, index }')
								v-spacer(v-if='index === 0')
								span(v-if='index === 0')
									span {{ item }}
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import { setCookie, getCookie } from '@/util/cookies';
import cookieType from '@/assets/constant/cookieType';

export default {
	components: {
		ValidationProvider
	},
	props: ['currencyStatus'],
	data() {
		return {
			areaSwitch: true,
			domainSwitch: true,
			area: [],
			areaList: [],
			value: [],
			domain: [],
			turnOff: false,
			domainMap: {},
			currency: localStorage.getItem('currency'),
			rateList: [],
			menuProps: {
				offsetY: true
			},
			getSiteArr : [],
		};
	},
	methods: {
		selectItemName(data, items, index) {
			return (index === 0 && data.length !== items.length) || (index === 0 && items.length === 1);
		},
		showSelectAll(data, items, index) {
			return index === 1 && data.length === items.length;
		},
		selectMultiple(data, items, index) {
			return index === 1 && data.length !== items.length;
		},
		areaToggle() {
			this.$nextTick(() => {
				if (this.selectAllArea) {
					this.area = [];
				} else {
					this.area = this.areaList.slice();
				}
			});
		},
		toggle() {
			this.$nextTick(() => {
				if (this.selectAll) {
					this.value = [];
				} else {
					this.value = this.domain.slice();
				}
			});
		},
		toArray(obj) {
			const result = [];
			for (const prop in obj) {
				const value = obj[prop];
				if (typeof value === 'object') {
					result.push(this.toArray(value)); // <- recursive call
				}
				else {
					result.push(value);
				}
			}
			return this.getSiteArr = result;
		}
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList']),
		selectAllArea() {
			return this.area.length === this.areaList.length;
		},
		selectAll() {
			return this.value.length === this.domain.length;
		}
	},
	watch: {
		currency: function() {
			setCookie(cookieType.NAME.CURRENCY, this.currency, cookieType.EXPIRE.NEVER);
			this.$emit('emitDomainValue', this.value, this.domainMap, this.currency);
		},
		value: function() {
			this.$emit('emitDomainValue', this.value, this.domainMap, this.currency);
		},
		domain: function(value) {
			const arr = JSON.parse(JSON.stringify(value));
			if (arr.length === 0) {
				this.turnOff = true;
			} else {
				this.turnOff = false;
			}
		},
		area: function(value) {
			let tmp = {};
			for (let i of value) {
				let key = this.getDomain['OCMS'][i];
				for (let j in key) {
					tmp[j] = JSON.parse(JSON.stringify(key[j]));
				}
			}
			this.domainMap = tmp;
			let tmpKey = Object.keys(tmp);
			tmpKey.sort();
			this.domain = tmpKey;
			this.value = this.domain;
			if (this.domain.length <= 1) {
				this.domainSwitch = false;
			} else {
				this.domainSwitch = true;
			}
		}
	},
	created() {
		this.areaList = Object.keys(this.getDomain['OCMS']);
		const getSite = this.getDomain['OCMS'];
		this.areaList.sort();
		this.area = this.areaList;
		this.toArray(getSite);

		if (this.areaList.length <= 1) {
			this.areaSwitch = false;
		}
		this.rateList = this.getCurrencyList;
		if (!this.rateList.includes(getCookie(cookieType.NAME.CURRENCY))) {
			setCookie(cookieType.NAME.CURRENCY, "THB", cookieType.EXPIRE.NEVER);
		}
		this.currency = getCookie(cookieType.NAME.CURRENCY);
		this.$emit('emitDomainValue', this.value, this.domainMap, this.currency);
	}
};
</script>
