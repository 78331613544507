<template lang="pug">
	ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
		v-overlay(:value="show" opacity='0.8' z-index='6')
			v-progress-circular(indeterminate color="primary")
		v-card
			v-tabs.filter-tab(v-model='tab' color='#fff' hide-slider)
				v-tab(:ripple='false') {{$t('charts.everyDay')}}
			v-tabs-items(v-model='tab')
				v-tab-item
					v-card.pa-4.pb-0.filter-wrap
						v-row
							v-col.px-3.py-0(cols='12' sm='8' md='6' lg='3')
								DaliyPicker(@emitupdateDate='updateDate')
			v-card(v-resize='onResize').pt-0.pa-4.pb-4.filter-wrap
				v-row.justify-end.exportBtnUpper
					v-col.pa-4(v-if="windowSize.x <= 599")
					v-col.text-right.pa-0(v-if="windowSize.x > 599")
						div(@click="showhelperText")
							ExportBtn(:exportResult='getMemberActivityObj' @emitexportData='exportXlsxFile')
							span(:class="{ show: helperShow }").ml-auto.mr-7.helperText {{$t('common.helpText')}}
				MainSelectItem(:currencyStatus='currencyStatus' @emitDomainValue='updateDomainValue')
				v-row.justify-center
					v-col(cols='12' sm='8' md='6' lg='3')
						v-btn.searchBtn.defult-btn-color(@click='passes(send)' dark rounded style='width:100%; height: 55px;') {{$t('common.search')}}
				v-card.pa-4.filter-wrap.div-w
					v-col.py-0.fixBar.searchDate.justify-center(cols='12' md='6' lg='5' @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols="auto")
								span.sub-title  {{searchDates[0]}} ～ {{searchDates[1]}}
							v-col.mr-2.py-1.px-3(cols="auto")
								v-img.researchImg(max-width="25px" :src='imgSrc')
				v-row.justify-center(v-if='basic.items.length !== 0')
							v-col.pa-0(cols='12')
								v-data-table#dataTable.memberActivity.elevation-1.dataTableWrap(:headers='basic.headers' :items='basic.items' :disable-pagination='true' :footer-props="{ disablePagination: true }" :disable-sort='true')
									template(slot="items" slot-scope="myprops")
										tbody
											tr(v-for="item in basic.headers")
												td(style="min-width:150px;width:150px;max-width:150px") {{myprops.item[header.value]}}
</template>

<script>
import _ from 'lodash';
import statusCode from '@/assets/constant/statusCode'; 
import { getMemberActivity } from '@/api/General';
import { mapActions, mapGetters } from 'vuex';
import { commaFormatter, toThousandslsFilter } from '@/util/format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DaliyPicker from '@/components/DatePicker/DaliyPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem from '@/components/MainSelectItemOCMS';
import { exportXlsx } from '@/util/xlsx';

export default {
	components: {
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		ExportBtn,
		MainSelectItem
	},
	data() {
		return {
			dialog: false,
			show: false,
			loading: false,
			windowSize: {
				x: 0,
			},
			currencyStatus: false,
			helperShow: false,
			basic: {
				headers: [],
				items: []
			},
			getMemberActivityObj: {},
			dataItem: null,
			dates: [],
			searchDates: [],
			value: [],
			domainMap: {},
			tab: null,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize () {
			this.windowSize = { x: window.innerWidth};
		},
		smoothDown(){
			$('html, body').animate({
				scrollTop: $('.searchBtn').offset().top
			}, 500);
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						let tmpJ = this.domainMap[i].split('-');
						domain.push(tmpJ[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}
				const reqData = {
					startDate: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
					endDate: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
					domain
				};
				this.searchDates = [reqData.startDate,reqData.endDate];
				const res = await getMemberActivity(reqData);
				if (res.status === statusCode.STATUS_OK) {			
					const result = res.result.res;
					this.getMemberActivityObj = JSON.parse(JSON.stringify(result));
					const memberActivityAfterROE = result.ctx_daily;
					memberActivityAfterROE.sort(
						function(a, b) {     						     
							if (a.site_id === b.site_id) {
								return a.dim_ptt > b.dim_ptt ? 1 : -1;
							}					
							return  a.site_id.localeCompare(b.site_id);
						});
					// console.log(memberActivityAfterROE);
					await this.dataTable(memberActivityAfterROE);
					this.smoothDown();
					this.tab = 0;
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		dataTable(memberActivity) {
			this.dataItem = memberActivity.length;
			// 依keyName分類arr
			var output = {}, item;
			for (var i = 0; i < memberActivity.length; i++) {
				item = memberActivity[i];
				for (var prop in item) {
					if (item.hasOwnProperty(prop)) {
						// if this keys doesn't exist in the output object, add it
						if (!(prop in output)) {
							output[prop] = [];
						}
						// add data onto the end of the key's array
						output[prop].push(item[prop]);
					}
				}
			}
			// 取得欄位arr
			var headerArr = output.col_name.filter(function(elem, index, self) {
				return index === self.indexOf(elem);
			});	
			// 欄位arr排序	
			headerArr.sort();
			let firstSortArr = ['dpst_count','promo_off_dpst_count','promo_on_dpst_count','reg_users_dpst_count','reg_users'];
			for(let i = 0 ; i < firstSortArr.length; i ++ ){
				var first = firstSortArr[i];
				headerArr.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });
			}
			// 取得日期arr
			var dateArr = output.dim_ptt.filter(function(elem, index, self) {
				return index === self.indexOf(elem);
			});
			// 如果memberActivity物件對應日期裡沒有其欄位資料就填0
			for(let x = 0 ; x < dateArr.length; x ++ ){
				let arr = [];
				for(let i = 0 ; i < memberActivity.length; i ++ ){
					if(memberActivity[i].dim_ptt === dateArr[x]){
						arr.push(memberActivity[i]);
					}
				}
				for(let i = 0 ; i < headerArr.length; i ++ ){
					const index = arr.findIndex(x => x.col_name === headerArr[i]);
					if(index === -1){
						let obj = {
							col_name: headerArr[i],
							dim_ptt: arr[0].dim_ptt,
							col_value: 0
						};
						memberActivity.push(obj);
					}
				}
			}
			
			memberActivity.sort(
				function(a, b) {     						     		
					return a.dim_ptt > b.dim_ptt ? 1 : -1;
				});

			// 將資料塞進header
			const dataObj = { text: ' Date ', align: 'start', value: 'date'};
			this.basic.headers.push(dataObj);
			for (let j = 0; j < headerArr.length; j++){
				const headerObj = {};
				headerObj['text'] = headerArr[j];
				headerObj['value'] = headerArr[j];
				headerObj['align'] = 'right';
				this.basic.headers.push(headerObj);
				if(headerObj['text'] === 'reg_users_dpst_count'){
					headerObj['text'] = 'Reg. Users Dpst. Count';
					headerObj['value'] = 'regUsersDpst';
				}else if(headerObj['text'] === 'promo_off_dpst_count'){
					headerObj['text'] = 'Promo Off Dpst. Count';
					headerObj['value'] = 'promoOffDpst';
				}else if(headerObj['text'] === 'promo_on_dpst_count'){
					headerObj['text'] = 'Promo On Dpst. Count';
					headerObj['value'] = 'promoOnDpst';
				}else if(headerObj['text'] === 'dpst_count'){
					headerObj['text'] = 'Dpst. Count';
					headerObj['value'] = 'dpstCount';
				}else if(headerObj['text'] === 'reg_users'){
					headerObj['text'] = 'Reg. Users';
					headerObj['value'] = 'regUsers';
				}
			}
			// 將資料塞進item
			var container = [];	
			for(let y = 0 ; y < headerArr.length; y++){
				var newArray = [];
				for (let i = 0; i < memberActivity.length; i++) {
					if(memberActivity[i].col_name === headerArr[y]){				
						newArray.push(memberActivity[i].col_value);				
					}
				}
				container.push(newArray);		
			}
			for (let j = 0; j < dateArr.length; j++){
				const basicObj = {};
				basicObj[this.basic.headers[0].value] = dateArr[j];

				for(let x = 1; x < this.basic.headers.length; x++){
					basicObj[this.basic.headers[x].value] = toThousandslsFilter(container[x-1][j]);
					
				}
				this.basic.items.push(basicObj);					
			}
		},
		async exportXlsxFile(){
			try {
				let result = this.getMemberActivityObj;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		allClean() {
			this.basic.items = [];
			this.basic.headers = [];
		},
		toInt(data) {
			return _.toInteger(data.replace(/,/g, ''));
		},
		centNumToInteger(number) {
			return commaFormatter(number);
		},
		updateDomainValue(val,val2){
			this.value = val;
			this.domainMap = val2;
		},
		updateDate(val) {
			this.dates = val;
		},
		goSearch() {
			$('body,html').animate({scrollTop:0},800);
		},
		showhelperText(){
			if(Object.keys(this.getMemberActivityObj).length=== 0){
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		}
	},
	watch: {
		tab: function() {
			$(function() {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function() {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		getMemberActivityObj: function() {
			if(Object.keys(this.getMemberActivityObj).length!== 0){
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList', 'getExchangeRate', 'getDialogShow', 'getStatusDialog']),
	},
	created() {
		this.allClean();
	}
};
</script>
