import _ from 'lodash';
import moment from 'moment';
import repla from '@/util/dataFilter';
import fetch from '@/util/fetch';
import statusCode from '@/assets/constant/statusCode';

// 取得 office
export async function getBankAccountung(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/bankAccountung',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
			i.dpst_amt = parseFloat(i.dpst_amt);
		}
	}
	return result;
}
export async function getChannel(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/channel',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
			i.dpst_amt = parseFloat(i.dpst_amt);
			i.dpst_users = _.toString(i.dpst_users);
			i.dpst_with_promo_users = _.toString(i.dpst_with_promo_users);
			i.dpst_with_promo_amt = parseFloat(i.dpst_with_promo_amt);
			i.promo_on_dpst_amt = parseFloat(i.promo_on_dpst_amt);
			i.promo_on_dpst_users = _.toString(i.promo_on_dpst_amt);
			i.reg_users = _.toString(i.reg_users);
			i.reg_users_dpst_amt = parseFloat(i.reg_users_dpst_amt);
		}
	}
	return result;
}
export async function getDpstCountBucket(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/dpstCountBucket',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
			i.dpst_users = _.toString(i.dpst_users);
		}
	}
	return result;
}
export async function getMemberActivity(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/memberActivity',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
		}
	}
	return result;
}
export async function getServiceBanking(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/serviceBanking',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
			i.appove_amt = parseFloat(i.appove_amt);
			i.reject_amt = parseFloat(i.reject_amt);
		}
	}
	return result;
}
export async function getServiceCustomer(reqData) {
	const result = await fetch({
		url: '/cars/ocms/office/serviceCustomer',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		const res = await repla(tmp);
		for (const i of res.ctx_daily) {
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
		}
	}
	return result;
}
